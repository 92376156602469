import { createSlice } from "@reduxjs/toolkit";
const initialState=[];

const bookingDetailSlice = createSlice({    
    name:'bookingDetail',
    initialState,
    reducers:{
        bookingDetailData(state,action){
            state.push(action.payload)
        },      
    }
   
})
export const {bookingDetailData} = bookingDetailSlice.actions
export default bookingDetailSlice.reducer;