const INITIAL_STATE = "";
 
 let UploadProfileImg = (state = INITIAL_STATE, action) => {
     if (action.type === 'UPDATE_PROFILE_IMG') {
         return action.data
     } else {
         return state;
     }
 };
 
 export default UploadProfileImg;