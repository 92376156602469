import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trueIcon: [{
    detailTick: '',
    addSportTick: '',
  }],
  // other properties...
};

const TrueIconSlice = createSlice({
  name: 'trueIcon',
  initialState,
  reducers: {
    setTrueIcon(state, action) {
      state.trueIcon = action.payload;
      console.log(state.trueIcon, 'state.trueIcon');
    },
  },
});

export const { setTrueIcon } = TrueIconSlice.actions;
export default TrueIconSlice.reducer;