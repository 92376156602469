import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const SessionExpiredModal = ({ show , onClose }) => {
    const navigate = useNavigate();

    const loginHandle = () => {
        onClose();
        navigate("/login");
    }

    return (
        <Modal className="sessionExpired" show={show} size="md">
            <Modal.Header>
                <Modal.Title>Session Expired</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="p-0 m-0">Your session has expired. please log in again to continue!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={loginHandle}
                >
                    Login Again
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SessionExpiredModal;
