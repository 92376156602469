import { createSlice } from "@reduxjs/toolkit";
const initialState= {
    editRow : false
};

const editTournamentSlice = createSlice({   
    name:'editTournament',
    initialState,
    reducers:{
        seteditTournament(state,action){
            // state.push(action.payload)
            state.editRow = action.payload;
        }, 
        
       
    }
   
})
export const {seteditTournament} = editTournamentSlice.actions
export default editTournamentSlice.reducer;