import { createSlice } from "@reduxjs/toolkit";
const initialState=[];

const venueDetailSlice = createSlice({    
    name:'venueDetail',
    initialState,
    reducers:{
        setVenueDetail(state,action){
            state.push(action.payload)
        },      
    }
   
})
export const {setVenueDetail} = venueDetailSlice.actions
export default venueDetailSlice.reducer;