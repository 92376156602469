import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { setLoggedOut } from "../../store/userRoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { setTournamentId } from "../../store/tournamentIdSlice";
import config from "../../config/config";
const env = process.env.REACT_APP_ENV || "production";
const img_url = "../../../../images/";
const Logout = ({closeNav}) => {

  const userDetail = useSelector((state) => state.userRoleId.user);
  const rememberMeUserDetails = JSON.parse(localStorage.getItem("rememberedUser"));
  const profilePhoto = useSelector(state => state.profilePhoto);
  let BASE_URL = config[env].IMG_URL;
  let imgUrl = profilePhoto && profilePhoto ? profilePhoto : userDetail?.avatar
  if (imgUrl) {
    imgUrl =  imgUrl && imgUrl?.includes('http') ? imgUrl : BASE_URL + imgUrl
  }
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };

  let name = userDetail?.name;
  let firstLetter = name ? name.charAt(0).toUpperCase() : "NA";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    Api.CoreApiToken("logout", "POST")
      .then((response) => {
        if (response.message == 'Success')
          localStorage.clear();
        // dispatch(setLoggedOut())
        // dispatch(setTournamentId())
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const profileHandle =() =>{
    closeNav()
  }

  return (
    <div className="logout">

      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <span className="userName">
            {imageError || !imgUrl ?
              <span>{firstLetter}</span> :
              <><img src={imgUrl} alt="user" onError={handleImageError} /></>
            }
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="profileinfo">
            <span className="userName">
              {imageError || !imgUrl ?
                <span>{firstLetter}</span> :
                <><img src={imgUrl} alt="user" onError={handleImageError} /></>
              }
            </span>
            <div>
              <label className="name">{userDetail?.name}</label>
              <span className="email">{userDetail?.email}</span>
              {userDetail?.roleId == 3 ?
                <Link onClick={profileHandle} className="profileButton mb-2" to="organiserProfile">View Profile</Link>
                :
                <Link onClick={profileHandle} className="profileButton mb-2" to="playerProfile" >View Profile</Link>
              }
            </div>
          </div>
          {userDetail?.roleId == 3 &&
            <Dropdown.Item className="myBooking" ><Link to="/ticket-list">My Booking</Link></Dropdown.Item>
          }
          <Dropdown.Item className="logoutBtn mt-2" onClick={logout}>
            <img className="logoutIcon default" src={img_url + "logoutIcon.svg"} />
            <img className="logoutIcon hover" src={img_url + "logoutIcon_hover.svg"} />Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default Logout;
