import config from "../config/config";
import { toast } from "react-toastify";
import store from "../redux/store"; // Ensure your Redux store import is correct
import { setLoggedIn, setUserRoleId } from "../store/userRoleSlice";
const env = process.env.REACT_APP_ENV || "production";
export default class Api{

static sessionExpiredCallback = null;

  static setSessionExpiredCallback(callback) {
    this.sessionExpiredCallback = callback;
  }
    static call(url, method = "GET", data) {
        let BASE_URL = config[env].API_URL;
        let token=localStorage.getItem('token')
         
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Accept": "application/json",
        };
  
      // Add Authorization header if token is available
      if (token) {
          headers.Authorization = `bearer ${token}`;
      }

        return new Promise((resolve, reject) => {
          fetch(BASE_URL + url, {
            method: method || "GET",
            body: JSON.stringify(data),
            mode: "cors",
            headers: headers,
          })
            .then((response) => {
              if (response.ok) {
                resolve(response.json());
              } else {
                console.log(response.status);
                switch (response.status) {
                  case 404:
                    console.log("Object not found");
                    break;
                   case 401:
                localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
                break;
                  case 500:
                    console.log("Internal server error");
                    break;
                  default:
                    console.log("Some error occured");
                    break;
                }
                resolve(response.json());
              }
            })
            .catch((error) => {
              console.log(error);
              reject("Error");
            });
        });
    }
    static CoreApi(url, method = "GET", data) {
        let BASE_URL = config[env].API_URL;
      // console.log("Calling API: " + method + " " + url);
      if (data) {
        // console.log(data);
      }
      return new Promise((resolve, reject) => {
        fetch(BASE_URL + url, {
          method: method || "GET",
          body: JSON.stringify(data),
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              resolve(response.json());
            } else {
              console.log(response.status);
              switch (response.status) {
                case 404:
                  console.log("Object not found");
                  break;
                case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
                break;
                case 500:
                  console.log("Internal server error");
                  break;
                default:
                  console.log("Some error occured");
                  break;
              }
              resolve(response.json());
            }
          })
          .catch((error) => {
            console.log(error);
            reject("Error");
          });
      });
    }
    static CoreApiToken(url, method = "GET", data) {
      let BASE_URL = config[env].API_URL;
      const token = localStorage.getItem("token");
      // const token = store.getState().token;
      return new Promise((resolve, reject) => {
        fetch(BASE_URL + url, {
          method: method || "GET",
          body: JSON.stringify(data),
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          },
        })
          .then((response) => {
            // response only can be ok in range of 2XX
            if (response.ok) {
              resolve(response.json());
            } else {
              //handle errors in the way you want to
              switch (response.status) {
                case 404:
                  console.log("Object not found");
                  break;
                case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
                case 500:
                  console.log("Internal server error");
                  break;
                default:
                  console.log("Some error occurred");
                  break;
              }
              response.json().then((json) => {
                return reject(json);
              });
            }
          })
          .catch((error) => {
            //it will be invoked mostly for network errors
            //do what ever you want to do with error here
            console.log(error);
            reject("Error");
          });
      });
    }
     static ScoringApiToken(url, method = "GET", data) {
        let BASE_URL = config[env].API_URL_SCORING;
        const token = localStorage.getItem("token");
        return new Promise((resolve, reject) => {
          fetch(BASE_URL + url, {
            method: method || "GET",
            body: JSON.stringify(data),
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Accept: "application/json",
              Authorization: `Bearer ${token}`
            },
          })
            .then((response) => {
              // response only can be ok in range of 2XX
              if (response.ok) {
                resolve(response.json());
              } else {
                //handle errors in the way you want to
    
                switch (response.status) {
                  case 404:
                    console.log("Object not found");
                    break;
                  case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
                  case 500:
                    console.log("Internal server error");
                    break;
                  default:
                    console.log("Some error occurred");
                    break;
                }
    
                response.json().then((json) => {
                  return reject(json);
                });
              }
            })
            .catch((error) => {
              //it will be invoked mostly for network errors
              //do what ever you want to do with error here
              console.log(error);
              reject("Error");
            });
        });
      }
      static handleLogout() {
    // Dispatch logout actions
    store.dispatch(setLoggedIn(false));
    store.dispatch(setUserRoleId(""));
    // window.location.hash = "#/login";
  }
}