import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

const tournamentTypeSlice = createSlice({    
    name: 'tournamentType',
    initialState,
    reducers: {
        getTournamentType(state, action) {
            return action.payload;
        },
    }
});

export const { getTournamentType } = tournamentTypeSlice.actions;
export default tournamentTypeSlice.reducer;
