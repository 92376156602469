let config = {
  qa: {
    API_URL: "https://qabackend.zemo.co.in/ws/",
    IMG_URL: "https://qa.zemo.co.in/storage/",
    LIVE_URL: "https://qa.zemo.co.in/#/my-booking/",
    ZEMO_SUITE_URL: "https://qasuite.zemo.co.in/#",
    API_URL_SCORING: "https://qascoring.zemo.co.in/api/",
    ZEMO_URL: "https://qa.zemo.co.in/#/"
  },
  rc: {
    API_URL: "https://qabackend.zemo.co.in/ws/",
    IMG_URL: "https://zemo.co.in/storage/",
    LIVE_URL: "https://qa.zemo.co.in/#/my-booking/",
    ZEMO_SUITE_URL: "https://suite.zemo.co.in/#",
    API_URL_SCORING: "https://scoring.zemo.co.in/api/",
    ZEMO_URL: "https://rc.zemo.co.in/#/"
  },
  production: {
    API_URL: "https://backend.zemo.co.in/ws/",
    IMG_URL: "https://zemo.co.in/storage/",
    LIVE_URL: "https://zemo.co.in/#/my-booking/",
    ZEMO_SUITE_URL: "https://suite.zemo.co.in/#",
    API_URL_SCORING: "https://scoring.zemo.co.in/api/",
    ZEMO_URL: "https://zemo.co.in/#/"
  },
};

export const Role = {
  organizer : 3,
  player : 2
} 

export default config;
