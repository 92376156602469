import { combineReducers } from "redux";
import TicketPayloadReducer from "./reducers/ticketPayload";
import bookingDetailReducer from '../store/bookingDetailSlice';
import tournamentIdReducer from '../store/tournamentIdSlice';
import ticketDetailsReducer from '../store/ticketDetailsSlice';
import userRoleIdReducer from '../store/userRoleSlice';
import trueIconReducer from '../store/trueIconSlice';
import editTournamentReducer from '../store/editTournamentSlice';
import venueDetailReducer from '../store/venueDetailSlice';
import tournamentDetailReducer from '../store/tournamentDetailSlice';
import UploadProfileImg from "./reducers/profileImg";
import tournamentTypeReducer from "../store/tournamentTypeSlice";


const appReducer = combineReducers({
    /* your app’s top-level reducers */
    TicketPayload: TicketPayloadReducer,
    bookingDetail: bookingDetailReducer,  
    tournamentId :tournamentIdReducer,
    ticketDetails: ticketDetailsReducer,
    userRoleId:userRoleIdReducer,
    trueIconItem:trueIconReducer,
    editTournament:editTournamentReducer,
    venueDetail:venueDetailReducer,
    tournamentDetail:tournamentDetailReducer,
    profilePhoto : UploadProfileImg,
    tournamentType: tournamentTypeReducer
});

export default appReducer;
