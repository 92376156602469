import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    tournamentId: null,
    // other properties...
  };

const TournamentIdSlice = createSlice({   
    name:'tournamentId',
    initialState,
    reducers:{

        setTournamentId(state,action){
            state.tournamentId = action.payload;
        }, 
        
       
    }
   
})
export const {setTournamentId} = TournamentIdSlice.actions
export default TournamentIdSlice.reducer;