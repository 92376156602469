import { createSlice } from "@reduxjs/toolkit";
const initialState=[];

const tournamentDetailSlice = createSlice({    
    name:'tournamentDetail',
    initialState,
    reducers:{
        settournamentDetail(state,action){
            state.push(action.payload)
        },      
    }
   
})
export const {settournamentDetail} = tournamentDetailSlice.actions
export default tournamentDetailSlice.reducer;