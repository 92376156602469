const INITIAL_STATE = true;

let TicketPayloadReducer = (state = INITIAL_STATE, action) => {
    if (action.type === 'UPDATE_TICKET_PAYLOAD') {
        return action.data
    } else {
        return state;
    }
};

export default TicketPayloadReducer;

