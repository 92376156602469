import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const ticketDetailsSlice = createSlice({
    name: "ticketDetails",
    initialState,
    reducers: {
        setTicketDetails(state, action) {
            return action.payload;
        },
    },
});

export const { setTicketDetails } = ticketDetailsSlice.actions;
export default ticketDetailsSlice.reducer;