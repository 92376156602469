import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    userRoleId: null,
    user: {},
    isLoggedIn: false,
    // other properties...
  };

const userRoleSlice = createSlice({    
    name:'userRoleId',
    initialState,
    reducers:{
        setUserRoleId(state,action){
            state.userRoleId = action.payload.roleId;
        },  
        setUser(state,action){
            state.user = action.payload;
        },
        setLoggedIn(state,action){
            state.isLoggedIn = action.payload;
        }, 
        setLoggedOut(state,action){
            state.isLoggedIn = false;
            state.user = {};
            state.userRoleId = null;
        },    
    }
   
})
export const {setUserRoleId, setUser , setLoggedIn, setLoggedOut} = userRoleSlice.actions
export default userRoleSlice.reducer;